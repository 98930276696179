import { createMuiTheme } from '@material-ui/core/styles';

export const BASE_FONT_SIZE = 10;

const Theme = createMuiTheme({
  typography: {
    // Adjust Material UI REM calculations to match
    // the font adjustments we use globally
    htmlFontSize: BASE_FONT_SIZE,
    fontFamily: ['Frutiger', 'Arial', 'Verdana'].join(','),
  },
  palette: {
    background: {
      default: '#0090d0',
      paper: '#FFFFFF',
    },
    primary: {
      //O2 brand blue
      main: '#0019A5',
      dark: '#000066',
    },
    secondary: {
      //O2 brand light blue
      main: '#0090D0',
      light: '#41B6E6',
    },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '0px',
      },
      elevation1: {
        boxShadow: 'none',
      },
      elevation24: {
        // Modal Dialog
        minWidth: '450px',
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '1rem',
      },
    },
    MuiDialog: {
      root: {
        // Set to the top of the window
        zIndex: 'inherit',
      },
    },
    MuiChip: {
      label: {
        fontSize: '1.2rem',
      },
    },
  },
});

export default Theme;
