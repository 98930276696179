import React from 'react';

import { Button, Link as ExternalLink, Paper, Typography } from '@material-ui/core';
import { Link, RouteComponentProps } from '@reach/router';
import styled from 'styled-components';

const Styles = styled.section`
  min-height: 100vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  > .MuiPaper-root {
    flex: 1 1 auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    > * {
      padding: 1rem 0;
    }

    > a {
      display: flex;

      > button {
        flex: 1 1 auto;
      }
    }
  }
`;

export const Legal: React.FC<RouteComponentProps> = () => (
  <Styles>
    <Paper elevation={5}>
      <Typography variant="h3">Legal Information</Typography>
      <Typography variant="body1">
        You have accessed a computer system managed by Telefonica UK Limited. You are required to
        have authorisation from Telefonica UK Limited before you proceed and you are strictly
        limited to the use set out within that authorisation. Unauthorised access to or misuse of
        this system is prohibited and constitutes an offence under the Computer Misuse Act 1990.
      </Typography>
      <Typography variant="h4">Company Details</Typography>
      <Typography variant="body1">
        Telefónica UK Limited is registered in England and Wales. Registration number:1743099.
      </Typography>
      <Typography variant="body1">Its registered office is at:</Typography>
      <Typography variant="body1">
        260 Bath Road,
        <br />
        Slough,
        <br />
        Berkshire,
        <br />
        SL1 4DX,
        <br />
        United Kingdom
      </Typography>
      <Typography variant="body1">
        Telefónica UK Limited is registered under VAT number: GB 778 6037 85
      </Typography>
      <Typography variant="body1">
        Call: <ExternalLink href="tel:01132722000">0113 272 2000</ExternalLink>
      </Typography>
      <Typography variant="body1">
        This site is managed by The Lab by O2{' '}
        <ExternalLink href="mailto:admin@o2labs.co.uk">admin@o2labs.co.uk</ExternalLink>
      </Typography>
      <Link to="/">
        <Button variant="contained">Home</Button>
      </Link>
    </Paper>
  </Styles>
);
