import React from 'react';
import ReactDOM from 'react-dom';
import { StylesProvider, ThemeProvider as MaterialThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import Theme from './styles/Theme';
import GlobalStyle from './styles/GlobalStyle';

import App from './components/App';

ReactDOM.render(
  <StylesProvider injectFirst>
    <StyledThemeProvider theme={Theme}>
      <MaterialThemeProvider theme={Theme}>
        <CssBaseline />
        <GlobalStyle />
        <App />
      </MaterialThemeProvider>
    </StyledThemeProvider>
  </StylesProvider>,
  document.getElementById('root')
);
