import { LatLng } from 'leaflet';
import { MapId, Direction, Floor, Side, getMapId as getMapIdFromTuple } from './maps';

type PointOfInterestBase = {
  id: string;
  location: LatLng;
  side: Side;
  floor: Floor;
};

export type MeetingRoom = PointOfInterestBase & {
  type: 'meeting-room';
  name: string;
  direction: Direction;
};

export type Amenity = PointOfInterestBase & {
  type: 'amenity';
  name: string;
};

export type QuietSpace = PointOfInterestBase & {
  type: 'quiet-space';
  code: string;
  direction: Direction;
};

export type DeskRange = PointOfInterestBase & {
  type: 'desk-range';
  direction: Direction;
  start: number;
  end: number;
};

export type PointOfInterest = MeetingRoom | Amenity | QuietSpace | DeskRange;
export type PointOfInterestType = PointOfInterest['type'];

export const getMapId = (pointOfInterest: PointOfInterest): MapId =>
  getMapIdFromTuple([pointOfInterest.side, pointOfInterest.floor]);

const escapeId = (name: string): string =>
  encodeURIComponent(
    name
      .toLowerCase()
      .replace(/['.]/g, '') // remove
      .replace('ü', 'u') // subtitute
      .replace(/[^a-z0-9]+/g, '-') // strip
  );

const deskRange = (
  side: Side,
  floor: Floor,
  direction: Direction,
  start: number,
  end: number,
  location: LatLng
): DeskRange => ({
  id: start === end ? `desk-${start}` : `desks-${start}-${end}`,
  type: 'desk-range',
  side,
  floor,
  direction,
  start,
  end,
  location,
});

const meetingRoom = (
  side: Side,
  floor: Floor,
  direction: Direction,
  name: string,
  location: LatLng
): MeetingRoom => ({
  id: escapeId(name),
  type: 'meeting-room',
  side,
  floor,
  direction,
  name,
  location,
});

const amenity = (side: Side, floor: Floor, name: string, location: LatLng): Amenity => ({
  id: escapeId(name),
  type: 'amenity',
  side,
  floor,
  name,
  location,
});

const amenities = [
  amenity('W', 'G', 'Xerox', new LatLng(51.521656, -0.641048)),
  amenity('W', '3', 'Terrace', new LatLng(51.520414, -0.617981)),
  amenity('E', 'G', 'Explore', new LatLng(51.521108, -0.638763)),
  amenity('E', 'G', 'Interview Room 1 & 2', new LatLng(51.521031, -0.637512)),
  amenity('E', 'G', 'Classroom 1', new LatLng(51.521078, -0.6363)),
  amenity('E', 'G', 'Classroom 2', new LatLng(51.521124, -0.634197)),
  amenity('E', 'G', 'Retail Store', new LatLng(51.51854, -0.638832)),
  amenity('E', 'G', 'WHSmith Convenience Store', new LatLng(51.518527, -0.637158)),
  amenity('E', 'G', 'IT Support Room', new LatLng(51.523559, -0.629542)),
  amenity('E', 'G', 'Gym', new LatLng(51.524746, -0.625159)),
  amenity('E', 'G', 'Fitness Studio', new LatLng(51.524927, -0.629042)),
  amenity('E', 'G', 'Costa Coffee Bar', new LatLng(51.520374, -0.625126)),
  amenity('E', '2', 'Restaurant', new LatLng(51.519434, -0.634354)),
  amenity('E', '2', 'Winter Garden', new LatLng(51.519941, -0.63841)),
];

const meetingRoomsWG = [
  meetingRoom('W', 'G', 'R', 'Windsor Great Park', new LatLng(51.522072, -0.627662)),
  meetingRoom('W', 'G', 'R', 'Richmond Park', new LatLng(51.524181, -0.627019)),
  meetingRoom('W', 'G', 'R', 'Retiro Park', new LatLng(51.522232, -0.622512)),
  meetingRoom('W', 'G', 'R', 'Battersea Park', new LatLng(51.524221, -0.61541)),
  meetingRoom('W', 'G', 'B', 'Hyde Park', new LatLng(51.518709, -0.622538)),
  meetingRoom('W', 'G', 'B', 'Greenwich Park', new LatLng(51.518015, -0.624062)),
  meetingRoom('W', 'G', 'B', 'Roundhey Park', new LatLng(51.517361, -0.625456)),
  meetingRoom('W', 'G', 'B', 'Regents Park', new LatLng(51.516747, -0.626765)),
  meetingRoom('W', 'G', 'B', 'Soho Square', new LatLng(51.516787, -0.62889)),
  meetingRoom('W', 'G', 'B', 'Park Güell', new LatLng(51.518575, -0.628482)),
  meetingRoom('W', 'G', 'E', 'Bishops Park', new LatLng(51.517558, -0.640855)),
  meetingRoom('W', 'G', 'E', 'Parliament Square', new LatLng(51.519546, -0.636735)),
  meetingRoom('W', 'G', 'Q', 'Ruskin Park', new LatLng(51.524192, -0.631027)),
];

const desksWG = [
  deskRange('W', 'G', 'E', 1, 9, new LatLng(51.517548, -0.631886)),
  deskRange('W', 'G', 'E', 10, 17, new LatLng(51.516201, -0.631392)),
  deskRange('W', 'G', 'E', 18, 25, new LatLng(51.516374, -0.633323)),
  deskRange('W', 'G', 'E', 26, 34, new LatLng(51.517522, -0.634739)),
  deskRange('W', 'G', 'E', 35, 42, new LatLng(51.516201, -0.634761)),
  deskRange('W', 'G', 'E', 43, 50, new LatLng(51.516201, -0.63652)),
  deskRange('W', 'G', 'E', 51, 53, new LatLng(51.517589, -0.636864)),
  deskRange('W', 'G', 'E', 54, 61, new LatLng(51.516228, -0.638022)),
  deskRange('W', 'G', 'E', 62, 69, new LatLng(51.516294, -0.639782)),
  deskRange('W', 'G', 'E', 70, 74, new LatLng(51.516201, -0.641284)),
  deskRange('W', 'G', 'Q', 75, 80, new LatLng(51.522821, -0.64152)),
  deskRange('W', 'G', 'Q', 81, 92, new LatLng(51.523554, -0.640705)),
  deskRange('W', 'G', 'Q', 92, 101, new LatLng(51.523981, -0.639009)),
  deskRange('W', 'G', 'Q', 102, 112, new LatLng(51.523915, -0.636546)),
  deskRange('W', 'G', 'Q', 113, 120, new LatLng(51.524061, -0.634529)),
  deskRange('W', 'G', 'Q', 121, 128, new LatLng(51.523728, -0.632963)),
  deskRange('W', 'G', 'Q', 129, 129, new LatLng(51.524328, -0.632083)),
  deskRange('W', 'G', 'Q', 130, 130, new LatLng(51.523394, -0.631053)),
  deskRange('W', 'G', 'R', 131, 131, new LatLng(51.523395, -0.626671)),
  deskRange('W', 'G', 'R', 132, 139, new LatLng(51.523968, -0.625341)),
  deskRange('W', 'G', 'R', 140, 147, new LatLng(51.523915, -0.62356)),
  deskRange('W', 'G', 'R', 148, 152, new LatLng(51.523942, -0.620406)),
  deskRange('W', 'G', 'R', 153, 161, new LatLng(51.522167, -0.620577)),
  deskRange('W', 'G', 'R', 162, 172, new LatLng(51.522021, -0.618968)),
  deskRange('W', 'G', 'R', 173, 180, new LatLng(51.523928, -0.618904)),
  deskRange('W', 'G', 'R', 181, 188, new LatLng(51.523982, -0.617123)),
  deskRange('W', 'G', 'R', 189, 197, new LatLng(51.522234, -0.617208)),
];

const meetingRoomsW1 = [
  meetingRoom('W', '1', 'E', 'Golden Acre Park', new LatLng(51.518612, -0.631156)),
  meetingRoom('W', '1', 'E', 'Bramley Park', new LatLng(51.518732, -0.641348)),
  meetingRoom('W', '1', 'Q', 'Victoria Park', new LatLng(51.520468, -0.636714)),
  meetingRoom('W', '1', 'Q', 'Queens Park', new LatLng(51.521335, -0.636027)),
  meetingRoom('W', '1', 'Q', `St. Edna's Park`, new LatLng(51.521309, -0.63092)),
  meetingRoom('W', '1', 'Q', 'Langley Park', new LatLng(51.524179, -0.630856)),
  meetingRoom('W', '1', 'R', 'Alexandra Park', new LatLng(51.524192, -0.6268)),
];

const desksW1 = [
  deskRange('W', '1', 'B', 1, 5, new LatLng(51.517683, -0.623807)),
  deskRange('W', '1', 'B', 6, 22, new LatLng(51.518231, -0.62621)),
  deskRange('W', '1', 'B', 23, 32, new LatLng(51.516829, -0.626017)),
  deskRange('W', '1', 'E', 33, 37, new LatLng(51.516097, -0.631251)),
  deskRange('W', '1', 'E', 38, 48, new LatLng(51.51595, -0.633075)),
  deskRange('W', '1', 'E', 49, 59, new LatLng(51.516163, -0.635993)),
  deskRange('W', '1', 'E', 60, 67, new LatLng(51.516107, -0.637743)),
  deskRange('W', '1', 'E', 68, 75, new LatLng(51.51612, -0.63976)),
  deskRange('W', '1', 'E', 76, 81, new LatLng(51.516054, -0.641198)),
  deskRange('W', '1', 'E', 82, 89, new LatLng(51.517335, -0.640705)),
  deskRange('W', '1', 'E', 90, 90, new LatLng(51.518015, -0.64137)),
  deskRange('W', '1', 'E', 91, 91, new LatLng(51.518589, -0.639911)),
  deskRange('W', '1', 'Q', 92, 99, new LatLng(51.521554, -0.640748)),
  deskRange('W', '1', 'Q', 100, 107, new LatLng(51.522621, -0.640748)),
  deskRange('W', '1', 'Q', 108, 113, new LatLng(51.523728, -0.641198)),
  deskRange('W', '1', 'Q', 114, 121, new LatLng(51.523742, -0.639524)),
  deskRange('W', '1', 'Q', 122, 129, new LatLng(51.523835, -0.637958)),
  deskRange('W', '1', 'Q', 130, 140, new LatLng(51.523662, -0.63592)),
  deskRange('W', '1', 'Q', 141, 151, new LatLng(51.523995, -0.63313)),
  deskRange('W', '1', 'Q', 152, 152, new LatLng(51.523315, -0.630898)),
  deskRange('W', '1', 'R', 153, 176, new LatLng(51.522343, -0.626252)),
  deskRange('W', '1', 'R', 177, 177, new LatLng(51.523277, -0.626616)),
  deskRange('W', '1', 'R', 178, 185, new LatLng(51.523731, -0.625265)),
  deskRange('W', '1', 'R', 186, 190, new LatLng(51.523771, -0.623741)),
  deskRange('W', '1', 'R', 191, 195, new LatLng(51.521543, -0.62402)),
  deskRange('W', '1', 'R', 197, 205, new LatLng(51.521937, -0.62048)),
  deskRange('W', '1', 'R', 206, 210, new LatLng(51.523831, -0.62048)),
  deskRange('W', '1', 'R', 211, 218, new LatLng(51.523858, -0.61887)),
  deskRange('W', '1', 'R', 219, 229, new LatLng(51.521817, -0.618699)),
  deskRange('W', '1', 'R', 230, 238, new LatLng(51.52211, -0.616961)),
  deskRange('W', '1', 'R', 239, 246, new LatLng(51.523791, -0.617089)),
  deskRange('W', '1', 'R', 250, 263, new LatLng(51.521536, -0.627089)),
];

const meetingRoomsW2 = [
  meetingRoom('W', '2', 'R', 'Monfrague Park', new LatLng(51.522506, -0.628268)),
  meetingRoom('W', '2', 'R', 'Botanic Gardens', new LatLng(51.521852, -0.628118)),
  meetingRoom('W', '2', 'R', 'Malaga Park', new LatLng(51.522212, -0.62638)),
  meetingRoom('W', '2', 'R', 'Tollcross Park', new LatLng(51.522199, -0.624448)),
  meetingRoom('W', '2', 'R', 'Pheonix Park', new LatLng(51.522399, -0.622968)),
  meetingRoom('W', '2', 'B', 'Holland Park', new LatLng(51.518278, -0.622769)),
  meetingRoom('W', '2', 'B', 'Digital Natives', new LatLng(51.517784, -0.623906)),
  meetingRoom('W', '2', 'B', 'Creative Communities', new LatLng(51.517223, -0.625129)),
  meetingRoom('W', '2', 'B', 'High Flyers', new LatLng(51.516622, -0.626438)),
  meetingRoom('W', '2', 'B', 'Horsky Park', new LatLng(51.518144, -0.628348)),
  meetingRoom('W', '2', 'E', 'Clarence Park', new LatLng(51.518507, -0.632856)),
  meetingRoom('W', '2', 'E', 'Finsbury Park', new LatLng(51.518614, -0.63601)),
  meetingRoom('W', '2', 'E', 'Bethmann Park', new LatLng(51.518707, -0.641375)),
  meetingRoom('W', '2', 'E', `Nobel's Garden`, new LatLng(51.519468, -0.636869)),
  meetingRoom('W', '2', 'Q', 'Franciscan Garden', new LatLng(51.520456, -0.636761)),
  meetingRoom('W', '2', 'Q', 'Murillo Park', new LatLng(51.521257, -0.641396)),
  meetingRoom('W', '2', 'Q', 'Openshaw Park', new LatLng(51.52135, -0.63616)),
];

const desksW2 = [
  deskRange('W', '2', 'E', 1, 5, new LatLng(51.515959, -0.631285)),
  deskRange('W', '2', 'E', 6, 16, new LatLng(51.515973, -0.633173)),
  deskRange('W', '2', 'E', 17, 27, new LatLng(51.516133, -0.636005)),
  deskRange('W', '2', 'E', 28, 35, new LatLng(51.516093, -0.637894)),
  deskRange('W', '2', 'E', 36, 43, new LatLng(51.516133, -0.639696)),
  deskRange('W', '2', 'E', 44, 49, new LatLng(51.516106, -0.641241)),
  deskRange('W', '2', 'E', 50, 57, new LatLng(51.517334, -0.640748)),
  deskRange('W', '2', 'E', 58, 58, new LatLng(51.518068, -0.641391)),
  deskRange('W', '2', 'E', 59, 59, new LatLng(51.518641, -0.639868)),
  deskRange('W', '2', 'Q', 60, 60, new LatLng(51.521271, -0.639911)),
  deskRange('W', '2', 'Q', 61, 61, new LatLng(51.521992, -0.641348)),
  deskRange('W', '2', 'Q', 62, 69, new LatLng(51.522539, -0.640576)),
  deskRange('W', '2', 'Q', 70, 75, new LatLng(51.523835, -0.641134)),
  deskRange('W', '2', 'Q', 76, 83, new LatLng(51.523768, -0.639482)),
  deskRange('W', '2', 'Q', 84, 91, new LatLng(51.523928, -0.63798)),
  deskRange('W', '2', 'Q', 92, 102, new LatLng(51.523782, -0.635855)),
  deskRange('W', '2', 'Q', 103, 113, new LatLng(51.523955, -0.633109)),
  deskRange('W', '2', 'Q', 114, 118, new LatLng(51.523928, -0.631306)),
  deskRange('W', '2', 'R', 119, 126, new LatLng(51.523715, -0.626048)),
  deskRange('W', '2', 'R', 127, 134, new LatLng(51.523808, -0.624267)),
  deskRange('W', '2', 'R', 135, 142, new LatLng(51.523795, -0.620533)),
  deskRange('W', '2', 'R', 143, 150, new LatLng(51.523848, -0.618817)),
  deskRange('W', '2', 'R', 151, 158, new LatLng(51.523782, -0.617057)),
  deskRange('W', '2', 'R', 159, 170, new LatLng(51.522007, -0.617486)),
  deskRange('W', '2', 'R', 171, 179, new LatLng(51.521887, -0.619074)),
  deskRange('W', '2', 'R', 180, 182, new LatLng(51.522528, -0.620276)),
  deskRange('W', '2', 'R', 183, 185, new LatLng(51.5215, -0.621156)),
  deskRange('W', '2', 'R', 186, 186, new LatLng(51.52162, -0.622915)),
];

const meetingRoomsW3 = [
  meetingRoom('W', '3', 'R', 'Everglades', new LatLng(51.524166, -0.626843)),
  meetingRoom('W', '3', 'R', 'Speakers Corner', new LatLng(51.524141, -0.61555)),
  meetingRoom('W', '3', 'R', 'Black Park', new LatLng(51.521926, -0.616409)),
  meetingRoom('W', '3', 'R', 'National Park', new LatLng(51.521939, -0.617868)),
  meetingRoom('W', '3', 'R', 'Olympic Park', new LatLng(51.521859, -0.61922)),
  meetingRoom('W', '3', 'B', 'Picos de Europa', new LatLng(51.516142, -0.628881)),
  meetingRoom('W', '3', 'B', 'City Park', new LatLng(51.516917, -0.629032)),
  meetingRoom('W', '3', 'B', 'The O2', new LatLng(51.518652, -0.629847)),
  meetingRoom('W', '3', 'E', 'Glorieta Park', new LatLng(51.518612, -0.630963)),
  meetingRoom('W', '3', 'E', 'Brunel Park', new LatLng(51.518625, -0.635898)),
  meetingRoom('W', '3', 'E', 'St. James Park', new LatLng(51.518799, -0.640662)),
  meetingRoom('W', '3', 'E', 'Letna Hill', new LatLng(51.519506, -0.636778)),
  meetingRoom('W', '3', 'Q', 'Bravo Park', new LatLng(51.520534, -0.636778)),
  meetingRoom('W', '3', 'Q', 'Herbert Park', new LatLng(51.521255, -0.640748)),
  meetingRoom('W', '3', 'Q', 'Abantos', new LatLng(51.521308, -0.635962)),
  meetingRoom('W', '3', 'Q', 'Central Park', new LatLng(51.524139, -0.630856)),
];

const desksW3 = [
  deskRange('W', '3', 'B', 1, 22, new LatLng(51.518189, -0.625834)),
  deskRange('W', '3', 'B', 23, 25, new LatLng(51.517402, -0.625083)),
  deskRange('W', '3', 'B', 26, 28, new LatLng(51.516921, -0.626113)),
  deskRange('W', '3', 'B', 29, 30, new LatLng(51.516334, -0.627272)),
  deskRange('W', '3', 'B', 31, 35, new LatLng(51.516401, -0.631971)),
  deskRange('W', '3', 'B', 36, 44, new LatLng(51.516348, -0.633795)),
  deskRange('W', '3', 'B', 45, 49, new LatLng(51.516361, -0.635791)),
  deskRange('W', '3', 'B', 50, 61, new LatLng(51.516348, -0.63813)),
  deskRange('W', '3', 'B', 62, 75, new LatLng(51.516894, -0.640564)),
  deskRange('W', '3', 'B', 76, 76, new LatLng(51.518042, -0.640886)),
  deskRange('W', '3', 'B', 77, 81, new LatLng(51.518709, -0.638676)),
  deskRange('W', '3', 'B', 82, 84, new LatLng(51.519777, -0.638762)),
  deskRange('W', '3', 'Q', 85, 87, new LatLng(51.520243, -0.638826)),
  deskRange('W', '3', 'Q', 88, 92, new LatLng(51.521298, -0.63859)),
  deskRange('W', '3', 'Q', 93, 93, new LatLng(51.521925, -0.640951)),
  deskRange('W', '3', 'Q', 94, 105, new LatLng(51.523181, -0.64064)),
  deskRange('W', '3', 'Q', 106, 115, new LatLng(51.523968, -0.638387)),
  deskRange('W', '3', 'Q', 116, 126, new LatLng(51.523835, -0.63592)),
  deskRange('W', '3', 'Q', 127, 133, new LatLng(51.523902, -0.633795)),
  deskRange('W', '3', 'Q', 134, 136, new LatLng(51.523568, -0.632422)),
  deskRange('W', '3', 'Q', 137, 137, new LatLng(51.524275, -0.631993)),
  deskRange('W', '3', 'R', 139, 152, new LatLng(51.522167, -0.627959)),
  deskRange('W', '3', 'R', 153, 161, new LatLng(51.521847, -0.625792)),
  deskRange('W', '3', 'R', 163, 165, new LatLng(51.523555, -0.625534)),
  deskRange('W', '3', 'R', 166, 166, new LatLng(51.524289, -0.62577)),
  deskRange('W', '3', 'R', 167, 174, new LatLng(51.523888, -0.624225)),
  deskRange('W', '3', 'R', 175, 180, new LatLng(51.522061, -0.624375)),
  deskRange('W', '3', 'R', 181, 185, new LatLng(51.523928, -0.620534)),
  deskRange('W', '3', 'R', 186, 193, new LatLng(51.523955, -0.618861)),
  deskRange('W', '3', 'R', 194, 195, new LatLng(51.522728, -0.620363)),
  deskRange('W', '3', 'R', 196, 197, new LatLng(51.522754, -0.618646)),
  deskRange('W', '3', 'R', 198, 200, new LatLng(51.523648, -0.617552)),
  deskRange('W', '3', 'R', 201, 202, new LatLng(51.522754, -0.616887)),
  deskRange('W', '3', 'R', 203, 203, new LatLng(51.524355, -0.616736)),
];

const meetingRoomsEG = [
  meetingRoom('E', 'G', 'R', 'The Blueroom', new LatLng(51.518613, -0.635432)),
  meetingRoom('E', 'G', 'R', 'The Arena', new LatLng(51.518573, -0.632664)),
  meetingRoom('E', 'G', 'Q', 'Maria Luisa Park', new LatLng(51.520898, -0.631956)),
  meetingRoom('E', 'G', 'E', 'Oak Hill Park', new LatLng(51.521098, -0.625154)),
  meetingRoom('E', 'G', 'E', 'Alameda Park', new LatLng(51.519054, -0.624617)),
  meetingRoom('E', 'G', 'E', 'Whittington Park', new LatLng(51.518399, -0.624553)),
  meetingRoom('E', 'G', 'E', 'Saffron Gardens', new LatLng(51.519722, -0.616485)),
];

const desksEG = [
  deskRange('E', 'G', 'B', 1, 6, new LatLng(51.516173, -0.630147)),
  deskRange('E', 'G', 'B', 7, 15, new LatLng(51.516186, -0.63195)),
  deskRange('E', 'G', 'B', 16, 23, new LatLng(51.515412, -0.632358)),
  deskRange('E', 'G', 'B', 24, 35, new LatLng(51.514612, -0.631413)),
  deskRange('E', 'G', 'B', 36, 46, new LatLng(51.515252, -0.629933)),
  deskRange('E', 'G', 'B', 47, 57, new LatLng(51.515012, -0.626178)),
  deskRange('E', 'G', 'B', 58, 63, new LatLng(51.514465, -0.624933)),
  deskRange('E', 'G', 'B', 64, 72, new LatLng(51.515039, -0.623989)),
  deskRange('E', 'G', 'B', 73, 81, new LatLng(51.515973, -0.624354)),
  deskRange('E', 'G', 'B', 82, 87, new LatLng(51.515999, -0.626307)),
  deskRange('E', 'G', 'E', 88, 99, new LatLng(51.518536, -0.622079)),
  deskRange('E', 'G', 'E', 100, 115, new LatLng(51.518523, -0.619977)),
  deskRange('E', 'G', 'E', 116, 129, new LatLng(51.518576, -0.617917)),
  deskRange('E', 'G', 'E', 130, 143, new LatLng(51.521004, -0.617895)),
  deskRange('E', 'G', 'E', 144, 159, new LatLng(51.521058, -0.620084)),
  deskRange('E', 'G', 'E', 160, 171, new LatLng(51.521004, -0.622122)),
];

const meetingRoomsE1 = [
  meetingRoom('E', '1', 'E', 'Parc Guinardo', new LatLng(51.522824, -0.62253)),
  meetingRoom('E', '1', 'E', `St. Steven's Green`, new LatLng(51.521389, -0.625019)),
  meetingRoom('E', '1', 'E', 'Kelston Park', new LatLng(51.520034, -0.617187)),
  meetingRoom('E', '1', 'E', 'Abbey Park', new LatLng(51.518781, -0.625087)),
  meetingRoom('E', '1', 'B', 'Mote Park', new LatLng(51.517248, -0.621888)),
  meetingRoom('E', '1', 'B', 'Petrin Gardens', new LatLng(51.517282, -0.622843)),
  meetingRoom('E', '1', 'B', 'Upton Court Park', new LatLng(51.517709, -0.624399)),
  meetingRoom('E', '1', 'B', 'Mooragh Park', new LatLng(51.517882, -0.625128)),
  meetingRoom('E', '1', 'B', 'Trebah Park', new LatLng(51.517461, -0.626201)),
  meetingRoom('E', '1', 'R', 'Gately Park', new LatLng(51.517288, -0.634279)),
  meetingRoom('E', '1', 'R', 'Haynes Park', new LatLng(51.518729, -0.631833)),
  meetingRoom('E', '1', 'R', 'Manor House Park', new LatLng(51.52002, -0.639771)),
  meetingRoom('E', '1', 'R', 'Nuttall Park', new LatLng(51.521356, -0.631794)),
  meetingRoom('E', '1', 'Q', 'Taylor Park', new LatLng(51.522824, -0.634926)),
  meetingRoom('E', '1', 'Q', 'Springburn Park', new LatLng(51.522824, -0.633939)),
  meetingRoom('E', '1', 'Q', 'Wimbledon Common', new LatLng(51.522397, -0.629948)),
];

const desksE1 = [
  deskRange('E', '1', 'B', 1, 13, new LatLng(51.515627, -0.631394)),
  deskRange('E', '1', 'B', 14, 25, new LatLng(51.514973, -0.631416)),
  deskRange('E', '1', 'B', 26, 37, new LatLng(51.514973, -0.625322)),
  deskRange('E', '1', 'B', 38, 50, new LatLng(51.515627, -0.6253)),
  deskRange('E', '1', 'B', 51, 64, new LatLng(51.516227, -0.625279)),
  deskRange('E', '1', 'B', 65, 68, new LatLng(51.516814, -0.62648)),
  deskRange('E', '1', 'B', 69, 71, new LatLng(51.516801, -0.624377)),
  deskRange('E', '1', 'E', 72, 75, new LatLng(51.518891, -0.623267)),
  deskRange('E', '1', 'E', 76, 83, new LatLng(51.518914, -0.622369)),
  deskRange('E', '1', 'E', 84, 90, new LatLng(51.518891, -0.621336)),
  deskRange('E', '1', 'E', 91, 98, new LatLng(51.518931, -0.62037)),
  deskRange('E', '1', 'E', 99, 105, new LatLng(51.518878, -0.619254)),
  deskRange('E', '1', 'E', 106, 113, new LatLng(51.518905, -0.618246)),
  deskRange('E', '1', 'E', 114, 127, new LatLng(51.521213, -0.618632)),
  deskRange('E', '1', 'E', 128, 143, new LatLng(51.521306, -0.620735)),
  deskRange('E', '1', 'E', 144, 155, new LatLng(51.521213, -0.622645)),
  deskRange('E', '1', 'Q', 156, 161, new LatLng(51.523501, -0.626607)),
  deskRange('E', '1', 'Q', 162, 170, new LatLng(51.523474, -0.624783)),
  deskRange('E', '1', 'Q', 171, 178, new LatLng(51.524395, -0.624225)),
  deskRange('E', '1', 'Q', 179, 190, new LatLng(51.524995, -0.625277)),
  deskRange('E', '1', 'Q', 191, 201, new LatLng(51.524408, -0.626864)),
  deskRange('E', '1', 'Q', 202, 210, new LatLng(51.524955, -0.629869)),
  deskRange('E', '1', 'Q', 211, 216, new LatLng(51.525075, -0.632658)),
  deskRange('E', '1', 'Q', 217, 231, new LatLng(51.523674, -0.632164)),
  deskRange('E', '1', 'Q', 232, 240, new LatLng(51.523781, -0.630341)),
  deskRange('E', '1', 'R', 241, 252, new LatLng(51.521112, -0.634297)),
  deskRange('E', '1', 'R', 253, 268, new LatLng(51.521245, -0.636185)),
  deskRange('E', '1', 'R', 269, 282, new LatLng(51.521192, -0.638309)),
  deskRange('E', '1', 'R', 283, 296, new LatLng(51.518803, -0.638438)),
  deskRange('E', '1', 'R', 297, 312, new LatLng(51.518817, -0.6364)),
  deskRange('E', '1', 'R', 313, 324, new LatLng(51.51883, -0.634361)),
];

const meetingRoomsE2 = [
  meetingRoom('E', '2', 'E', 'Palmer Park', new LatLng(51.521255, -0.625019)),
  meetingRoom('E', '2', 'E', 'Coolilly Ree Gardens', new LatLng(51.518606, -0.625049)),
  meetingRoom('E', '2', 'B', 'Parkhead', new LatLng(51.517538, -0.624298)),
  meetingRoom('E', '2', 'B', 'Salt Hill Park', new LatLng(51.517765, -0.625071)),
];

const desksE2 = [
  deskRange('E', '2', 'B', 1, 11, new LatLng(51.516212, -0.632036)),
  deskRange('E', '2', 'B', 12, 22, new LatLng(51.516199, -0.630319)),
  deskRange('E', '2', 'B', 23, 30, new LatLng(51.516212, -0.626285)),
  deskRange('E', '2', 'B', 32, 36, new LatLng(51.516226, -0.625041)),
  deskRange('E', '2', 'B', 37, 37, new LatLng(51.516333, -0.624032)),
  deskRange('E', '2', 'B', 38, 38, new LatLng(51.515866, -0.623839)),
  deskRange('E', '2', 'E', 39, 47, new LatLng(51.518856, -0.622637)),
  deskRange('E', '2', 'E', 48, 56, new LatLng(51.518776, -0.621092)),
  deskRange('E', '2', 'E', 57, 62, new LatLng(51.518856, -0.620062)),
  deskRange('E', '2', 'E', 63, 68, new LatLng(51.521004, -0.620041)),
  deskRange('E', '2', 'E', 69, 77, new LatLng(51.521031, -0.621264)),
  deskRange('E', '2', 'E', 78, 86, new LatLng(51.520978, -0.62253)),
  deskRange('E', '2', 'Q', 87, 92, new LatLng(51.523367, -0.626457)),
  deskRange('E', '2', 'Q', 93, 101, new LatLng(51.523287, -0.624719)),
  deskRange('E', '2', 'Q', 102, 109, new LatLng(51.524208, -0.624247)),
  deskRange('E', '2', 'Q', 110, 121, new LatLng(51.524835, -0.625191)),
  deskRange('E', '2', 'Q', 122, 132, new LatLng(51.524235, -0.626671)),
  deskRange('E', '2', 'Q', 133, 138, new LatLng(51.524862, -0.629804)),
  deskRange('E', '2', 'Q', 139, 146, new LatLng(51.523621, -0.629761)),
];

export const allPointsOfInterest: PointOfInterest[] = [
  ...amenities,
  ...meetingRoomsWG,
  ...desksWG,
  ...meetingRoomsW1,
  ...desksW1,
  ...meetingRoomsW2,
  ...desksW2,
  ...meetingRoomsW3,
  ...desksW3,
  ...meetingRoomsEG,
  ...desksEG,
  ...meetingRoomsE1,
  ...desksE1,
  ...meetingRoomsE2,
  ...desksE2,
];
