import { MapLayer, MapLayerProps, withLeaflet } from 'react-leaflet';
import L from 'leaflet';

export interface ReactLeafletSvgOverlayProps extends MapLayerProps {
  svgImage: string | SVGElement;
  bounds: L.LatLngBoundsExpression;
  options?: L.ImageOverlayOptions;
}

class ReactLeafletSvgOverlay extends MapLayer<ReactLeafletSvgOverlayProps, L.SVGOverlay> {
  createLeafletElement(props: ReactLeafletSvgOverlayProps) {
    const { svgImage, bounds, options } = props;
    return L.svgOverlay(svgImage, bounds, options);
  }

  componentDidMount() {
    const { layerContainer, map } = this.props.leaflet || this.context;
    this.leafletElement.addTo(layerContainer);
    this.leafletElement.addTo(map);
  }

  componentWillUnmount() {
    const { layerContainer, map } = this.props.leaflet || this.context;
    map.removeLayer(this.leafletElement);
    layerContainer.removeLayer(this.leafletElement);
  }

  componentDidUpdate() {
    this.componentWillUnmount();
    this.leafletElement = this.createLeafletElement(this.props);
    this.componentDidMount();
  }
}

export default withLeaflet(ReactLeafletSvgOverlay);
