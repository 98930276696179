import { createGlobalStyle } from 'styled-components';
import { BASE_FONT_SIZE } from './Theme';
import frutiger45lighteot from '../assets/fonts/frutiger-45light.eot';
import frutiger45lightwoff from '../assets/fonts/frutiger-45light.woff';
import frutiger45lightttf from '../assets/fonts/frutiger-45light.ttf';
import frutiger45lightsvg from '../assets/fonts/frutiger-45light.svg';
import frutiger55romaneot from '../assets/fonts/frutiger-55roman.eot';
import frutiger55romanwoff from '../assets/fonts/frutiger-55roman.woff';
import frutiger55romanttf from '../assets/fonts/frutiger-55roman.ttf';
import frutiger55romansvg from '../assets/fonts/frutiger-55roman.svg';
import frutiger65boldeot from '../assets/fonts/frutiger-65bold.eot';
import frutiger65boldwoff from '../assets/fonts/frutiger-65bold.woff';
import frutiger65boldttf from '../assets/fonts/frutiger-65bold.ttf';
import frutiger65boldsvg from '../assets/fonts/frutiger-65bold.svg';

/*
  ----- GLOBAL STYLES -----
*/
const StyledGlobal = createGlobalStyle`
  /*
  PLEASE NOTE:

  Material UI CSSBaseline is included in /src/index.tsx to set default styles
  https://material-ui.com/components/css-baseline/

  This is similar to normalize, use /src/styles/MaterialTheme.ts to override:
  https://material-ui.com/customization/themes/
  */


  /* ---- FONTS ---- */
  @font-face {
    font-family: Frutiger;
    src: url(${frutiger45lighteot});
    src: url(${frutiger45lighteot}#iefix)
        format('embedded-opentype'),
      url(${frutiger45lightwoff}) format('woff'),
      url(${frutiger45lightttf}) format('truetype'),
      url(${frutiger45lightsvg}#Frutiger45Light)
        format('svg');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger45lighteot});
    src: url(${frutiger45lighteot}#iefix)
        format('embedded-opentype'),
      url(${frutiger45lightwoff}) format('woff'),
      url(${frutiger45lightttf}) format('truetype'),
      url(${frutiger45lightsvg}#Frutiger45Light)
        format('svg');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger45lighteot});
    src: url(${frutiger45lighteot}#iefix)
        format('embedded-opentype'),
      url(${frutiger45lightwoff}) format('woff'),
      url(${frutiger45lightttf}) format('truetype'),
      url(${frutiger45lightsvg}#Frutiger45Light)
        format('svg');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger55romaneot});
    src: url(${frutiger55romaneot}#iefix)
        format('embedded-opentype'),
      url(${frutiger55romanwoff}) format('woff'),
      url(${frutiger55romanttf}) format('truetype'),
      url(${frutiger55romansvg}#Frutiger55)
        format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger65boldeot});
    src: url(${frutiger65boldeot}#iefix)
        format('embedded-opentype'),
      url(${frutiger65boldwoff}) format('woff'),
      url(${frutiger65boldttf}) format('truetype'),
      url(${frutiger65boldsvg}#Frutiger65Bold)
        format('svg');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger65boldeot});
    src: url(${frutiger65boldeot}#iefix)
        format('embedded-opentype'),
      url(${frutiger65boldwoff}) format('woff'),
      url(${frutiger65boldttf}) format('truetype'),
      url(${frutiger65boldsvg}#Frutiger65Bold)
        format('svg');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger65boldeot});
    src: url(${frutiger65boldeot}#iefix)
        format('embedded-opentype'),
      url(${frutiger65boldwoff}) format('woff'),
      url(${frutiger65boldttf}) format('truetype'),
      url(${frutiger65boldsvg}#Frutiger65Bold)
        format('svg');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger65boldeot});
    src: url(${frutiger65boldeot}#iefix)
        format('embedded-opentype'),
      url(${frutiger65boldwoff}) format('woff'),
      url(${frutiger65boldttf}) format('truetype'),
      url(${frutiger65boldsvg}#Frutiger65Bold)
        format('svg');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger65boldeot});
    src: url(${frutiger65boldeot}#iefix)
        format('embedded-opentype'),
      url(${frutiger65boldwoff}) format('woff'),
      url(${frutiger65boldttf}) format('truetype'),
      url(${frutiger65boldsvg}#Frutiger65Bold)
        format('svg');
    font-weight: 900;
    font-style: normal;
  }

  /* ---- BASE ---- */
  html {
    /*
    Font size based on 10px simplification
    https://www.sitepoint.com/understanding-and-using-rem-units-in-css/

    10px is a much easier base value to work out rem values, e.g.
      34px = 3.4rem

    PLEASE NOTE:
    This same value is used in /src/styles/MaterialTheme.ts to apply the
    same effect to Material UI components
    */
    font-size: ${BASE_FONT_SIZE}px;

    /* For background gradiant */
    min-height: 100vh;
  }

  body {
    background: linear-gradient(180deg, #0019a5 0%, #0090d0 70%, #41b6e6 100%);
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
  }

  /* Override for modal internal scrolling */
  .overrideScroll {
    /* overflow: visible; */
  }

  .dialog {
    /* Find a better way to do this */
    /* DO NOT USE A VALUE --> Higher than 1100 Anywhwere esle */
    z-index: 1100 !important;
  }

  .MuiAutocomplete-popup {
    z-index: 1000 !important;
  }
`;

export default StyledGlobal;
