import React from 'react';

import { Link, Typography } from '@material-ui/core';
import styled from 'styled-components';

import theLabWht from '../../assets/images/The_Lab_Wht.png';

const TheLabLogoStyles = styled.span`
  > a {
    > span {
      color: white;
    }

    > img {
      margin-left: 0.5rem;
      width: 2rem;
      height: 2rem;
      vertical-align: middle;
    }
  }
`;

const TheLabLogo = () => {
  return (
    <TheLabLogoStyles className="the-lab-logo-container">
      <Link
        rel="noopener noreferrer"
        target="_blank"
        href="https://telefonica.workplace.com/groups/116385292399649/"
      >
        <Typography variant="caption">Powered by</Typography>
        <img src={theLabWht} alt="the-lab-logo" className="the-lab-logo" />
      </Link>
    </TheLabLogoStyles>
  );
};

export default TheLabLogo;
