import React from 'react';

import { RouteComponentProps } from '@reach/router';

import { isMapId } from '../../model/maps';
import { allPointsOfInterest, getMapId } from '../../model/pointsOfInterest';
import Home from './Home';

const HomeRoute: React.FC<
  RouteComponentProps<{ map: string; pointOfInterest: string }>
> = props => {
  const map = isMapId(props.map) ? props.map : undefined;
  const pointOfInterest = allPointsOfInterest.find(
    point => point.id === props.pointOfInterest && getMapId(point) === map
  );
  return <Home map={map} pointOfInterest={pointOfInterest} />;
};

export default HomeRoute;
