import React from 'react';

import { Link as ExternalLink, Paper, Typography } from '@material-ui/core';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import { Link } from '@reach/router';
import styled from 'styled-components';

import TheLabLogo from '../Assets/TheLabLogo';
import Map from '../Maps/Map';
import { MapId } from '../../model/maps';
import { PointOfInterest } from '../../model/pointsOfInterest';
import Header from '../Header/Header';
import HomeStyles from './HomeStyles';

export const StyledPaper = styled(Paper)`
  height: 100%;
  padding: 3rem;
`;

// Component
const Home: React.FC<{ map?: MapId; pointOfInterest?: PointOfInterest }> = props => {
  const map = props.map || 'EG';
  const selected = props.pointOfInterest ? props.pointOfInterest.location : undefined;

  // Render
  return (
    <HomeStyles>
      <header>
        <StyledPaper elevation={5}>
          <Header map={map} pointOfInterest={props.pointOfInterest} />
        </StyledPaper>
      </header>
      <main>
        <Map map={map} focus={selected} />
      </main>
      <footer>
        <Typography variant="caption">
          <Link to="/legal">Legal</Link>
        </Typography>
        <Typography variant="caption">
          <ExternalLink
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=DmBElwQ-Lkm6oSXsJFxvEMy_xGO4959Dn698qByrOYRURUc1Rk9PNFg4Q0tYMzJZRjhPSlBCUEtEMCQlQCN0PWcu"
            target="_blank"
            rel="noopener noreferrer"
          >
            Give us feedback
            <FeedbackOutlinedIcon fontSize="inherit" />
          </ExternalLink>
        </Typography>
        <TheLabLogo />
      </footer>
    </HomeStyles>
  );
};

export default Home;
