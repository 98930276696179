import React, { useEffect, useRef, useState } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import PublicIcon from '@material-ui/icons/Public';
import { navigate } from '@reach/router';
import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Map as LeafletMap, Marker } from 'react-leaflet';
import Control from 'react-leaflet-control';

import mapMarkerSelected from '../../assets/images/map-marker-selected.svg';
import E1 from '../../assets/maps/MR-BR-E1.svg';
import E2 from '../../assets/maps/MR-BR-E2.svg';
import EG from '../../assets/maps/MR-BR-EG.svg';
import W1 from '../../assets/maps/MR-BR-W1.svg';
import W2 from '../../assets/maps/MR-BR-W2.svg';
import W3 from '../../assets/maps/MR-BR-W3.svg';
import WG from '../../assets/maps/MR-BR-WG.svg';
import MapStyles from './MapStyles';
import SvgOverlay from './SvgOverlay';
import { MapId } from '../../model/maps';

// Data
const DEFAULT_BOUNDS = new Leaflet.LatLngBounds([[51.512967, -0.645258], [51.526836, -0.611874]]);

const FLY_OPTIONS: Leaflet.ZoomPanOptions = {
  duration: 1,
};

const markerIconSelected = new Leaflet.Icon({
  iconUrl: mapMarkerSelected,
  iconSize: [20, 20],
  className: 'marker-click-through',
});

// Helpers
const getSvg = (map: MapId): string => {
  const [side, floor] = [map[0], map[1]];
  switch (side) {
    case 'W':
      switch (floor) {
        case 'G':
          return WG;
        case '1':
          return W1;
        case '2':
          return W2;
        case '3':
          return W3;
      }
    // eslint-disable-next-line no-fallthrough
    case 'E':
      switch (floor) {
        case 'G':
          return EG;
        case '1':
          return E1;
        case '2':
          return E2;
      }
  }
  throw new Error('Invalid map reference');
};

type Props = { map: MapId; focus?: Leaflet.LatLng };

// Render
const BathRoadMap = (props: Props) => {
  // Local state
  const [minZoom, setMinZoom] = useState<number | null>(null);
  const [resetEnabled, setResetEnabled] = useState<boolean>(false);
  const [svgElement, setSvgElement] = useState<SVGElement | undefined>();

  // Refs
  const mapRef = useRef<LeafletMap>(null);

  /*
    ---- EVENTS ----
  */

  const whenReady = () => {
    if (!mapRef.current) {
      return;
    }

    // Set minimum zoomn to current (auto-zoomed based on DEFAULT_BOUNDS)
    const { leafletElement } = mapRef.current;

    const tempMinZoom = leafletElement.getZoom();

    leafletElement.setMinZoom(tempMinZoom);

    // Update local state (so we can enable/disable reset zoom)
    setMinZoom(tempMinZoom);

    if (props.focus) {
      mapRef.current.leafletElement.flyToBounds(props.focus.toBounds(400), FLY_OPTIONS);
    }
  };

  const onZoomEnd = () => {
    if (!mapRef.current || !minZoom) {
      return;
    }

    // Enable/disable reset zoom
    setResetEnabled(mapRef.current.leafletElement.getZoom() !== minZoom);
  };

  const resetZoom = () => {
    if (!mapRef.current) {
      return;
    }
    mapRef.current.leafletElement.flyToBounds(DEFAULT_BOUNDS);
    navigate(`/${props.map}`);
  };

  // Effects

  useEffect(() => {
    const svgPath = getSvg(props.map);
    fetch(svgPath)
      .then(response => response.text())
      .then(svgSource => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgSource, 'image/svg+xml');
        if (doc.documentElement instanceof SVGElement) {
          setSvgElement(doc.documentElement);
        }

        if (!mapRef.current || !props.focus) {
          return;
        }

        mapRef.current.leafletElement.flyToBounds(props.focus.toBounds(400), FLY_OPTIONS);
      });
    if (!mapRef.current) {
      return;
    }
    mapRef.current.leafletElement.flyToBounds(DEFAULT_BOUNDS, { animate: false });
    // eslint-disable-next-line
  }, [props.map]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    if (props.focus === undefined) {
      mapRef.current.leafletElement.flyToBounds(DEFAULT_BOUNDS, FLY_OPTIONS);
    } else {
      mapRef.current.leafletElement.flyToBounds(props.focus.toBounds(400), FLY_OPTIONS);
    }
  }, [props.focus]);

  /*
    ---- RENDER ----
  */

  return (
    <MapStyles>
      <LeafletMap
        ref={mapRef}
        bounds={DEFAULT_BOUNDS}
        maxBounds={DEFAULT_BOUNDS}
        whenReady={whenReady}
        onzoomend={onZoomEnd}
        attributionControl={false}
        onclick={e => {
          const clipinput = document.querySelector('#clipboard') as HTMLInputElement;
          if (clipinput) {
            clipinput.value = e.latlng.toString();
            clipinput.select();
            document.execCommand('copy');
          }
        }}
      >
        {/* Reset zoom */}
        <Control position="topleft">
          <div className="info-pane buttons">
            <div className="button" data-enabled={resetEnabled} onClick={resetZoom}>
              <Tooltip title="Reset zoom" placement="right">
                <PublicIcon fontSize="inherit" />
              </Tooltip>
            </div>
          </div>
        </Control>

        {svgElement ? <SvgOverlay svgImage={svgElement} bounds={DEFAULT_BOUNDS} /> : null}

        {/* A marker */}
        {props.focus && <Marker icon={markerIconSelected} position={props.focus} />}
      </LeafletMap>
      {// eslint-disable-next-line no-undef
      process.env.NODE_ENV === 'development' && (
        <input id="clipboard" type="text" style={{ position: 'absolute', top: -1000 }} />
      )}
    </MapStyles>
  );
};

export default BathRoadMap;
