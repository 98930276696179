import React from 'react';

import { Router, RouteComponentProps } from '@reach/router';

import { ErrorBoundary } from './ErrorBoundary';
import HomeRoute from './Home/HomeRoute';
import { Legal } from './Legal';
import { OfflineApp } from './OfflineApp';

const Stub: React.FC<RouteComponentProps> = props => <div>{props.children}</div>;

const App: React.FC = () => (
  <ErrorBoundary>
    <OfflineApp>
      <Router>
        <HomeRoute path="/" />
        <Legal path="legal" />
        <Stub path=":map">
          <HomeRoute path="/" />
          <HomeRoute path=":pointOfInterest" />
        </Stub>
      </Router>
    </OfflineApp>
  </ErrorBoundary>
);

export default App;
