import React from 'react';

import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { Arrays, pipe } from 'collection-fns';
import styled from 'styled-components';

import { MapId } from '../../model/maps';
import { allPointsOfInterest, PointOfInterest, getMapId } from '../../model/pointsOfInterest';
import HeaderStyles from './HeaderStyles';

type SelectOption = {
  name: string;
  link: string;
  group: string;
  altNames?: string[];
};

const pointOfInterestSelectOption = (
  pointOfInterest: PointOfInterest
): SelectOption | undefined => {
  switch (pointOfInterest.type) {
    case 'meeting-room':
      return {
        name: `MR BR ${pointOfInterest.floor}${pointOfInterest.side}${pointOfInterest.direction} ${pointOfInterest.name}`,
        group: 'Meeting Room',
        link: `/${getMapId(pointOfInterest)}/${pointOfInterest.id}`,
      };
    case 'amenity':
      return {
        name: pointOfInterest.name,
        group: 'Amenity',
        link: `/${getMapId(pointOfInterest)}/${pointOfInterest.id}`,
      };
    case 'desk-range':
      return {
        name:
          pointOfInterest.start === pointOfInterest.end
            ? `MR BR ${pointOfInterest.floor}${pointOfInterest.side}${pointOfInterest.direction}${pointOfInterest.start}`
            : `MR BR ${pointOfInterest.floor}${pointOfInterest.side}${pointOfInterest.direction}${pointOfInterest.start}-${pointOfInterest.end}`,
        group: 'Desks',
        link: `/${getMapId(pointOfInterest)}/${pointOfInterest.id}`,
        altNames: Arrays.init(
          { from: pointOfInterest.start, to: pointOfInterest.end },
          i =>
            `MR BR ${pointOfInterest.floor}${pointOfInterest.side}${pointOfInterest.direction}${i}`
        ),
      };
  }
  return;
};

const StyledFormControl = styled(FormControl)`
  ${props => props.theme.breakpoints.down('xs')} {
    margin: 0 2rem 0 0;
  }
  ${props => props.theme.breakpoints.up('sm')} {
    margin: 0 1rem;
  }
`;

const Header = (props: { map: MapId; pointOfInterest?: PointOfInterest }) => {
  return (
    <HeaderStyles>
      <form autoComplete="off" onSubmit={e => e.preventDefault()}>
        <div className="left">
          <StyledFormControl>
            <Select
              className="field"
              value={props.map.substr(0, 1)}
              onChange={e => {
                let newMap = e.target.value + props.map.substr(1, 1);
                if (newMap === 'E3') {
                  newMap = 'E2';
                }
                navigate(`/${newMap}`);
              }}
              inputProps={{ id: 'floor' }}
            >
              <MenuItem value={'W'}>West</MenuItem>
              <MenuItem value={'E'}>East</MenuItem>
            </Select>
          </StyledFormControl>
          <StyledFormControl>
            <Select
              className="field"
              value={props.map.substr(1, 1)}
              onChange={e => navigate(`/${props.map.substr(0, 1)}${e.target.value}`)}
              inputProps={{ id: 'floor' }}
            >
              <MenuItem value={'G'}>Ground</MenuItem>
              <MenuItem value={'1'}>1st Floor</MenuItem>
              <MenuItem value={'2'}>2nd Floor</MenuItem>
              {props.map[0] === 'W' ? <MenuItem value={'3'}>3rd Floor</MenuItem> : null}
            </Select>
          </StyledFormControl>
        </div>
        <div className="autocomplete">
          <Autocomplete<SelectOption>
            value={
              props.pointOfInterest ? pointOfInterestSelectOption(props.pointOfInterest) : undefined
            }
            options={pipe(
              allPointsOfInterest,
              Arrays.choose(pointOfInterestSelectOption),
              Arrays.sortBy(opt => opt.name),
              Arrays.sortByDescending(opt => opt.group)
            )}
            groupBy={option => option.group}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField {...params} placeholder="Highlight" fullWidth className="field" />
            )}
            onChange={(e: unknown, value: SelectOption | null) => {
              if (value) {
                navigate(value.link);
              } else {
                navigate(`/${props.map}`);
              }
            }}
          />
        </div>
      </form>
    </HeaderStyles>
  );
};

export default Header;
