import React, { useEffect, useRef } from 'react';

import { Location } from '@reach/router';

import { unregister } from '../serviceWorker';

/**
 * Include this component around your router to register a service worker to cache the application
 * so it works offline. Then, when the application has automatically updated itself, intercept the
 * Reach router location change and do a hard browser navigation to enble the application updates
 * to be applied.
 * @example
 * <OfflineApp>
 *   <Router>
 *     <HomeRoute path="/" />
 *   </Router>
 * </OfflineApp>
 */
export const OfflineApp: React.FC = props => {
  const updateReadyRef = useRef(false);
  useEffect(() => {
    unregister();
  }, []);
  return (
    <Location>
      {({ location }) => {
        if (updateReadyRef.current) {
          window.location.href = location.href;
        }
        return props.children;
      }}
    </Location>
  );
};
