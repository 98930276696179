export type Floor = 'G' | '1' | '2' | '3';
export type Side = 'W' | 'E';
export type Direction = 'E' | 'R' | 'Q' | 'B';
export type Map = [Side, Floor];
export type MapId = 'WG' | 'W1' | 'W2' | 'W3' | 'EG' | 'E1' | 'E2';
export const allMapIds: MapId[] = ['WG', 'W1', 'W2', 'W3', 'EG', 'E1', 'E2'];
export const isMapId = (map: unknown): map is MapId =>
  typeof map === 'string' && allMapIds.includes(map as MapId);
export const getMapFromId = (mapId: MapId): Map => [mapId[0] as Side, mapId[1] as Floor];
export const getMapId = (map: Map): MapId => {
  if (map[0] === 'E' && map[1] === '3') {
    throw new Error(`Map doesn't exist`);
  }
  return (map[0] + map[1]) as MapId;
};
