import styled from 'styled-components';

const MapStyles = styled.div`
  position: relative;
  flex: 1 1 auto;

  /* width: 100%; */
  /* height: 0; */

  /* padding-top: 60%; */

  > .loading {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: rgba(0, 0, 0, 0.15);

    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  > .leaflet-container {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: transparent;

    .info-pane {
      border: 0.2rem solid rgba(0, 0, 0, 0.2);
      background: white;
      background-clip: padding-box;

      padding: 0.4rem 0.6rem;
      border-radius: 0.4rem;

      h3 {
        margin: 0 0 0.2rem 0;

        text-align: center;
      }

      p {
        margin: 0;
      }

      > .row {
        display: flex;
        align-items: center;

        > .left {
          flex: 0 0 auto;

          width: 2.7rem;

          padding-right: 0.8rem;
        }

        > .right {
          flex: 1 1 auto;
        }

        .icon {
          font-size: 1.8rem;
          line-height: 1.3rem;
        }

        p {
          &.label {
            font-size: 1.1rem;
            font-weight: bold;
            text-align: right;
          }

          &.data {
            font-size: 1.3rem;
          }
        }
      }

      &.radios {
        .MuiFormControlLabel-root {
          margin: 0;
        }
        .MuiTypography-body1 {
          font-size: 1rem;
        }

        .MuiRadio-root {
          padding: 0;
        }

        .MuiSvgIcon-root {
          height: 1rem;
          width: 1rem;
        }
      }

      &.buttons {
        padding: 0;

        font-size: 1.75rem;

        &:not(:first-of-type) {
          margin-top: 1rem;
        }

        > .button {
          display: flex;

          > * {
            flex: 0 0 auto;
          }

          padding: 0.6rem;

          &:not(:first-of-type) {
            border-top: 1px solid #ccc;
          }

          /* Disabled by default */
          cursor: default;
          background-color: #f4f4f4;
          color: #bbb;

          &[data-enabled='true'] {
            cursor: pointer;

            background-color: white;
            color: black;

            &:hover {
              background-color: #f4f4f4;
            }
          }
        }
      }
    }

    .selected-marker path {
      fill: red;
    }
  }
`;

export default MapStyles;
