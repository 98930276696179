import styled from 'styled-components';

const HeaderStyles = styled.div`
  > form {
    display: flex;
    flex-wrap: wrap;

    > .left {
      display: flex;
      flex: 1 1 auto;

      > * {
        flex: 0 0 auto;
      }

      .field {
        font-weight: 600;
        font-size: 2.2rem;

        .MuiInput-input {
          font-size: 2.2rem;
          max-width: 20rem;
          font-weight: 600;

          &:not(.Mui-disabled) {
            color: ${props => props.theme.palette.secondary.main};
          }

          &.Mui-disabled {
            color: ${props => props.theme.palette.text.main};
          }
        }
      }
    }

    > .autocomplete {
      flex: 1 2 auto;
      min-width: 20rem;

      > * {
        flex: 0 0 auto;
      }

      .field {
        font-size: 2.2rem;
        font-weight: 600;

        .MuiInput-input {
          font-size: 2.2rem;
          font-weight: 600;

          &:not(.Mui-disabled) {
            color: ${props => props.theme.palette.secondary.main};
          }

          &.Mui-disabled {
            color: ${props => props.theme.palette.text.main};
          }
        }
      }
    }

    ${props => props.theme.breakpoints.down('xs')} {
      .text,
      .field {
        font-size: 1.8rem;
        .MuiInput-input {
          font-size: 1.8rem;
        }
      }
    }
  }
`;

export default HeaderStyles;
