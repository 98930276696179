import React, { ReactNode, ErrorInfo } from 'react';

interface Props {
  children?: ReactNode;
}

export class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error) {
      console.log(error, 'ERROR', errorInfo.componentStack);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <p>
            {`We've alerted our engineers to the problem and we'll track down the problem as soon as possible.`}
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
