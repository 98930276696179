import styled from 'styled-components';

const HomeStyles = styled.section`
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  > header {
    display: flex;
    ${props => props.theme.breakpoints.up('md')} {
      padding: 4rem 4rem 0 4rem;
    }
    flex: 0 0 auto;

    > div {
      flex: 1 1 auto;
    }
  }

  > main {
    display: flex;
    flex: 1 1 auto;
    padding-top: 4rem;
    ${props => props.theme.breakpoints.up('md')} {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    display: flex;
    flex-direction: column;
  }

  > footer {
    display: flex;
    justify-content: center;
    padding: 1rem;

    > span {
      padding: 0 1rem;

      a {
        text-decoration: none;
        color: white;
      }
    }
  }
`;

export default HomeStyles;
